@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 25px;
    height: 25px;
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #383636; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-td-container {
    text-align: -webkit-center !important;
  }

  .div_spinner_container {
    text-align: center !important;
    position: relative;
    display: inline-block;
  }