/* The Modal (background) */
.modal {
  /*display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #000000;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.modal-content-title {
  color: #feb400;
  font-size: 28px;
}

.modal-content-subtitle {
  color: #feb400;
  font-size: 22px;
}

.modal-content-text {
  color: white;
  font-size: 16px;
}

.modal-content-text-unmargered {
  color: white;
  font-size: 16px;
  margin: 0px;
}

.modal-content-img {
  max-width: 100%;
}

/* The Close Button */
.close {
  color: rgb(221, 221, 221);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: rgb(117, 117, 117);
  text-decoration: none;
  cursor: pointer;
}

.highlighted-text {
  color: #feb400;
}