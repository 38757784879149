.navbar {
    text-align: center;
    border-top: 3px solid black;
    padding-top: 4px;
    padding-bottom: 8px;
    width: 100%;
    background-color: black;
}

.navbar span {
    color: white;
}

.navbar span a {
    text-decoration: none;
}

.navbar_div {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: right;
    padding-right: 10%;
}

.navbar_button {
    background-color: black;
    color: white;
    cursor: pointer;
    position: relative;
    padding: 9px;
    font-weight: bold; 
    margin-left: 2px;
    border-width: 0px;
}

.navbar_button:hover {
    background-color: #ffb400;
    color: rgb(0, 0, 0);
}

.navbar_text {
    background-color: #ffb400;
    color: white;
    cursor: pointer;
    position: relative;
    padding: 9px;
    font-weight: bold; 
    margin-left: 16px;
    margin-right: -2px;
    border-width: 0px;
}