.footer {
    text-align: center;
    border-top: 3px solid black;
    padding-top: 4px;
    padding-bottom: 8px;
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: black;
}

.footer span {
    color: white;
}

.footer span a {
    text-decoration: none;
}