.header_div {
    align-items: center;
    height: 60px;
}

.header_div_title {
    color: black;
    font-size: 30px;
    margin: 30px;
    font-weight: bold;
}

.header_div_logo {
    width: 200px;
    height: 200px;
}

.header_div_tiles {
    width: 90%;
    margin-left: 5%;
}

.header_div_tile_left {
    width: 40%;
    float: left;
    display: inline-block;
    text-align: right;
    margin-top: 75px;
    
}

.header_div_tile_center {
    width: 20%;
    float: left;
    display: inline-block;
    text-align: center;
}

.header_div_tile_right {
    width: 40%;
    float: left;
    display: inline-block;
    text-align: left;
    margin-top: 75px;
}