.background_div {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
}

.container {
    width: 100%;
    height: 100%;
}



.AppContent {
    width: 90%;
    margin-left: 5%;
}

.form-label {
    color: orange;
    display: flex;
    margin-left: 29px;
    margin-top: 1em;

}


/* BUTTONS */
.btn {
    padding: 10px;
    border-width: 0px;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
}
.btn-primary {
    background-color: orange;
    color: white;
}
.btn-primary:hover {
    background-color: rgb(212, 138, 0);
}

.modal-button {
    color: white;
    background-color: orange;
    padding: 10px;
    border-width: 0px;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 2em;
}
.modal-button:hover {
    background-color: rgb(212, 138, 0);
}
.modal-button-secondary {
    color: rgb(0, 0, 0);
    background-color: rgb(180, 180, 180);
    padding: 10px;
    border-width: 0px;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 2em;
    margin-left: 1em;
}
.modal-button-secondary:hover {
    background-color: rgb(151, 151, 151);
}