/* FOOTER */
.footer span a {
    color: #feb400;
}



/* DROPDOWN */
.dropdown {
    color: orange;
}



/* HEADER */
.header_div_tile_left {
    background: rgb(242,208,51);
    background: linear-gradient(90deg, rgba(242,208,51,1) 0%, rgba(255,180,0,1) 100%);
    border-top: 4px solid black;
}
.header_div_tile_right {
    background: rgb(242,208,51);
    background: linear-gradient(90deg, rgba(242,208,51,1) 0%, rgba(255,180,0,1) 100%);
    border-top: 4px solid black;
}



/* TABLE */
.ranking_table_thead {
    background-color: black;
    color: white;
}
.ranking_table_tbody {
    background-color: rgb(0, 0, 0);
}
.ranking_table_tbody_td_name {
    background: rgb(242,208,51);
    background: linear-gradient(90deg, rgba(242,208,51,1) 0%, rgba(255,180,0,1) 100%);
    color: black !important;
}
.ranking_table_tbody td {
    color: white;
}
.ranking_table_tbody_td_rank {
    color: rgb(242,208,51);
}


/* TEMPLATE */
.container {
    background-color: rgba(255, 166, 0, 0.377);
    overflow-y: scroll;
}