.myteams_container {
    width: 100%;
}

.myteams_title {
    color: white;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.536);
}

.team_card_root_clearfix {
    width: 25%;
    text-align: -webkit-center;
    display: inline-block;
    margin-top: 1em;
}

.team_card {
    background-color: rgba(0, 0, 0, 0.459);
    width: 80%;
    border-radius: 5px;
    padding: 2%;
}
.team_card_image_div {
    width: 100%;
}
.team_card_image_img {
    width: 80%;
}
.team_card_title_h3 {
    color: white;
    font-size: 30px;
    margin: 0px;
}

.team_card_buttons_div {
    margin-top: 3px;
}

.team_card_buttons_btn {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: 0px;
    margin-left: 5px;
    cursor: pointer;
}
.team_card_buttons_btn_primary {
    background-color: rgb(255, 196, 0);
    color: black;
}
.team_card_buttons_btn_primary:hover {
    background-color: rgb(207, 159, 2);
}
.team_card_buttons_btn_secondary {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 196, 0);
}
.team_card_buttons_btn_secondary:hover {
    background-color: rgb(160, 160, 160);
}
.team_card_buttons_btn_danger {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 0, 0);
}
.team_card_buttons_btn_danger:hover {
    background-color: rgb(163, 142, 142);
}
.team_card_buttons_btn_green {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 196, 0);
}
.team_card_buttons_btn_green:hover {
    background-color: rgb(49, 49, 49);
}

.div_myteams_action_buttons {
    margin-top: 0px;
    margin-bottom: 2em;
}

.teams_cards_container {
    display: inline-block;
    margin-bottom: 3em;
    width: 100%;
}