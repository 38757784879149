.ranking_table {
    border: 0px;
    margin: auto;
}

.ranking_table_thead {
}
.ranking_table_thead th {
    padding: 6px;
}

.ranking_table_tbody {
}

.ranking_table_tbody_td_name {
    font-weight: bold;
}

.ranking_table_tbody td {
    text-align: center;
    font-size: 20px;
    padding: 6px;
}
.ranking_table_tbody_td_rank {
    font-size: 24px;
    font-weight: bold;
}