.login_container {
    background-color: rgb(0, 0, 0);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.login-signup_div {
    width: 100%;
}

.tab-button {
    width: 50%;
    padding: 1em;
    background-color: rgb(58, 58, 58);
    color: orange;
    border-width: 0px;
    font-weight: bold;
    cursor: pointer;
}
.tab-button:hover {
    background-color: rgb(34, 34, 34);
}

.tab-button-active {
    color: white;
    background-color: orange;
}
.tab-button-active:hover {
    color: white;
    background-color: rgb(221, 144, 0);
}


.form-title {
    color: white;
}

.form-input {
    display: block;
    background-color: black;
    color: white;
    padding: 0px;
    margin-top: 5px;
    width: 90%;
    margin-left: 5%;
    border: 0px solid orange;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
    border: 1px solid #686868;
}

.btn-submit {
    margin-top: 1em;
    margin-bottom: 1em;
    border-width: 0px;
    background-color: orange;
    padding: 1em;
    font-weight: bold;
    padding-left: 2em;
    padding-right: 2em;
    width: 90%;
    cursor: pointer;
}
.btn-submit:hover {
    background-color: rgb(228, 149, 4);
}

.message_b_error {
    color: rgb(255, 81, 0);
    display: block;
    padding-bottom: 1em;
}